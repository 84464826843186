import React from "react"
import { Box } from "reflexbox"
import { Hero } from "../components/Hero"
import { Body } from "../components/Body"
import { Footer } from "../components/Footer"
import { WhyUse } from "../components/WhyUse"
import SEO from "../components/seo"

import Layout from "../components/layout"

const IndexPage = () => {
  return (
    <>
      <SEO />
      <Layout>
        <Box flex={1}>
          <Hero />
          <Body />
          <WhyUse />
          <Footer />
        </Box>
      </Layout>
    </>
  )
}

export default IndexPage
