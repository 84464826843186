import React from "react"
import { Flex, Box } from "reflexbox"
import Blackwing from "@slalom/blackwing"
import styled from "styled-components"
import { SafeArea } from "../SafeArea"
// Components
import { FluidPlots } from "../FluidPlots"

const Root = styled(Flex)`
  position: relative;
  background-color: ${props => props.theme.colors.background[0]};
  z-index: 1;
`

const BlockContainer = styled.div`
  padding: 0px;
  display: flex;
  justify-content: center;
  padding: 15px;
  height: 100%;
`

type BlockProps = {
  backgroundColor: string
}

const Block = styled(Box)<BlockProps>`
  background-color: ${props => props.backgroundColor};
  max-width: 100%;
  height: 100%;
  padding: 2rem;
  /* color: #fff; */
  border-radius: 5px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  z-index: 9;

  ${props => `
    @media screen and (min-width: 40em) {
      padding: 30px 50px;
    }
  `}
`
const InfoContainer = styled(Flex)`
  position: relative;
  padding: 1rem 0 2rem;
  flex-direction: column;

  ${props => `
    @media screen and (min-width: 40em) {
      flex-direction: column;
      padding: 50px 0px;
    }
    @media screen and (min-width: 52em) {
      flex-direction: column;
    }
    @media screen and (min-width: 64em) {
      flex-direction: row;
    }
`}
`

type TextProps = {
  color?: string
}

const H1 = styled.h1<TextProps>`
  color: ${props => props.color || "inherit"};
  font-weight: 700;
  padding-bottom: 15px;
  font-size: 18px;
  line-height: 1.2em;

  ${props => `
    @media screen and (min-width: 40em) {
      font-size: 24px;
      line-height: 1.2em;      
    }
  `}
`

const H2 = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;

  ${props => `
    @media screen and (min-width: 40em) {
      font-size: 28px;
    }
  `}
`

const BoxOne = styled.div`
  margin: 0 auto;
  max-width: 34rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  text-align: center;

  @media screen and (min-width: 40em) {
    margin: 0 auto;
    max-width: 64rem;
    padding-top: 50px;
    padding-bottom: 100px;
  }
`

const P = styled.p<TextProps>`
  font-size: 16px;
  font-weight: 400;
`

export const Body: React.FC = props => {
  return (
    <Root id="Body">
      <SafeArea>
        <Flex flexDirection="column">
          <Box>
            <Flex>
              <BoxOne>
                <H2>
                  SparkThink is an easy-to-use tool to facilitate workshops,
                  conduct surveys, and move projects forward - in person or
                  remotely.
                </H2>
              </BoxOne>
            </Flex>
          </Box>

          <Box>
            <InfoContainer>
              <Box flex={6}>
                <BlockContainer>
                  <Block backgroundColor="#fff">
                    <H1 color="#d515b8">
                      Spark a world of productive discussion with SparkThink
                      Workshops.
                    </H1>
                    <span>
                      A SparkThink workshop is a live session where you can
                      democratize the idea-gathering process. Whether everyone’s
                      in the same room or working remotely, they all contribute
                      equally while you moderate, making collaboration simple.
                    </span>
                  </Block>
                </BlockContainer>
              </Box>
              <Box flex={6}>
                <BlockContainer>
                  <Block backgroundColor="fff">
                    <H1 color="#FFBA00">
                      Spark valuable feedback and actionable insights with
                      SparkThink Surveys.
                    </H1>
                    <span>
                      Across your office or around the world, SparkThink Surveys
                      let you gather everyone’s feedback for the consensus
                      needed to move projects forward. It’s easy to create and
                      distribute the type of survey you want and let
                      participants complete it on their own.
                    </span>
                  </Block>
                </BlockContainer>
              </Box>
            </InfoContainer>
          </Box>
        </Flex>
      </SafeArea>
    </Root>
  )
}
