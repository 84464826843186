import React from "react"
import styled from "styled-components"
import {
  motion,
  useViewportScroll,
  useTransform,
  Variants,
  Transition,
} from "framer-motion"
import Img from "gatsby-image"
import { Box } from "reflexbox/styled-components"

const Device = styled(motion.div)`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  margin: 0 auto;
  height: auto;
  width: 100%;
  padding: 12px;

  @media screen and (min-width: 64em) {
    height: 315px;
    width: 560px;
    padding: 12px;
  }

  @media screen and (min-width: 100em) {
    height: 405px;
    width: 720px;
    padding: 12px;
  }
`

const Image = styled(Img)`
  border: 2px solid #d3d3d3;
  border-radius: 5px;
  width: 100%;
  height: 100%;
`

const deviceVariants: Variants = {
  initial: {
    y: 100,

    opacity: 0,
    scale: 1.01,
  },
  enter: {
    scale: 1,
    opacity: 1,
    y: 0,

    transition: {
      type: "tween",
      ease: [0, 0, 0.2, 1],
      duration: 0.5,
      delay: 0.2,
    },
  },
}

type DemoProps = {
  image: any
}

export const Demo: React.FC<DemoProps> = props => {
  const { image } = props

  return (
    <Box width="100%" flex={1}>
      <Device variants={deviceVariants} initial="initial" animate="enter">
        <Image fluid={image.fluid} fadeIn={true} />
      </Device>
    </Box>
  )
}
