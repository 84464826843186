import React, { useState, useEffect } from "react"
import { AnimatePresence, motion, Variants, Transition } from "framer-motion"
import styled from "styled-components"
import { Flex, Box } from "reflexbox/styled-components"

type TextProps = {
  weight?: number
}

const Text = styled(motion.div)<TextProps>`
  font-size: 3em;
  line-height: 1;

  ${props => `
    @media screen and (min-width: 40em) {
      font-size: 3em;
    }

    @media screen and (min-width: 52em) {
      font-size: 3em;
    }

    @media screen and (min-width: 64em) {
      font-size: 3.5em;
    }

    @media screen and (min-width: 100em) {
      font-size: 5em;
    }
  `}
  
  font-weight: ${props => props.weight || 400};
`

const enterTransition: Transition = {
  type: "tween",
  ease: [0, 0, 0.2, 1],
  duration: 0.5,
}

const exitTransition: Transition = {
  type: "tween",
  ease: [1, 0.6, 0.3, 0],
  duration: 0.1,
}

const stagger: Transition = {
  when: "beforeChildren",
  staggerChildren: 0.2,
}

const staggerVariants: Variants = {
  initial: {},
  enter: {
    transition: stagger,
  },
}

const slideVariants: Variants = {
  initial: {
    y: "5em",
  },
  enter: {
    y: 0,
    transition: enterTransition,
  },
  exit: {
    y: "-2em",
    transition: exitTransition,
  },
}

const flipVariants: Variants = {
  initial: {
    y: "5em",
  },
  enter: {
    y: 0,
    transition: { ...enterTransition, delay: 0.1 },
  },
  exit: {
    y: "-2em",
    transition: exitTransition,
  },
}

type FlipperProps = {
  items: string[]
}

export const Flipper: React.FC<FlipperProps> = props => {
  const { items } = props

  // Local state
  const [index, setIndex] = useState(0)

  const tick = () => {
    setIndex(prevIndex => {
      return prevIndex === items.length - 1 ? 0 : prevIndex + 1
    })
  }

  useEffect(() => {
    const interval = window.setInterval(() => {
      tick()
    }, 2500)
    return () => {
      clearInterval(interval)
    }
  })

  return (
    <Flex flexDirection="column" width="100%" paddingBottom={40}>
      <motion.div animate="enter" variants={staggerVariants}>
        <Box overflow="hidden" paddingBottom="15px">
          <Text initial="initial" variants={slideVariants} weight={300}>
            Spark
          </Text>
        </Box>
        <Box overflow="hidden" paddingBottom="15px">
          <AnimatePresence exitBeforeEnter>
            <Text
              key={index}
              animate="enter"
              initial="initial"
              exit="exit"
              variants={flipVariants}
              weight={500}
            >
              {items[index]}
            </Text>
          </AnimatePresence>
        </Box>
        <Box overflow="hidden" paddingBottom="15px">
          <Text initial="initial" variants={slideVariants} weight={300}>
            with SparkThink
          </Text>
        </Box>
      </motion.div>
    </Flex>
  )
}
