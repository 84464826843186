import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { Flex, Box } from "reflexbox/styled-components"
import styled from "styled-components"
import { SafeArea } from "../SafeArea"
import { ChevronDown } from "react-feather"
import { Link, animateScroll as scroll } from "react-scroll"
import { motion, Variants } from "framer-motion"
// Components
import { Demo } from "../Demo"
import { Flipper } from "../Flipper"

const Root = styled(Flex)`
  width: 100%;
  color: #fff;
  position: relative;
`

const Slice = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  -webkit-transform: skewY(-10deg);
  transform: skewY(-10deg);
  -webkit-transform-origin: 0;
  transform-origin: 0;
  background: ${props => props.theme.colors.primary[500]};
`

const Container = styled(Flex)`
  flex-direction: column;
  padding: 150px 0;
  ${props => `
   @media screen and (min-width: 40em) {
      flex-direction: column;
    }
   @media screen and (min-width: 52em) {
      flex-direction: column;

    }
   @media screen and (min-width: 64em) {
      flex-direction: row;
      padding: 250px 0;
    }
`}
`

const IndicatorContainer = styled(Box)`
  position: absolute;
  bottom: 105px;
  left: 0;
  right: 0;
  padding: 15px;
`

const Indicator = styled(motion.custom(Box))`
  position: relative;
  margin: 0 auto;
  width: 3em;
  cursor: pointer;
  color: #000;
`

const IndicatorVariants: Variants = {
  initial: {
    y: 25,
    opacity: 0,
  },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.7,
      type: "tween",
      ease: [0, 0, 0.2, 1],
    },
  },
}

const actions = [
  "alignment",
  "productivity",
  "insights",
  "connections",
  "action",
  "decisions",
]

type HeroProps = {}

export const Hero: React.FC<HeroProps> = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "demo.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fixed(width: 920) {
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 696, maxHeight: 381) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const handleIndicatorClick = () => {
    const top = document.getElementById("Body").offsetTop
    scroll.scrollTo(top, {
      duration: 1000,
      delay: 100,
      smooth: true,
      containerId: "body1",
    })
  }

  return (
    <Root flexWrap="wrap" flex={1}>
      <Slice />
      <SafeArea>
        <Container
          height="100%"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
        >
          <Box flex={5} display="flex" alignItems="center" paddingRight={15}>
            <Flipper items={actions} />
          </Box>
          <Box flex={7} width="100%">
            <Demo image={data.file.childImageSharp} />
          </Box>
        </Container>
      </SafeArea>
      {/* <IndicatorContainer>
        <Link to="body" containerId="Body">
          <Indicator
            variants={IndicatorVariants}
            animate="enter"
            initial="initial"
            onClick={handleIndicatorClick}
          >
            <ChevronDown size="3em" />
          </Indicator>
        </Link>
      </IndicatorContainer> */}
    </Root>
  )
}
