import React from "react"
import { Flex, Box } from "reflexbox/styled-components"
import { SafeArea } from "../SafeArea"

import styled from "styled-components"

import {
  Organic,
  Speedometer,
  Chat,
  Happy,
  Integration,
  CloudUpload,
} from "@slalom/icons"

const H3 = styled.h3`
  font-size: 20px;
  line-height: 1.2;

  ${props => `
    @media screen and (min-width: 40em) {
      font-size: 20px;
    }
  `}
`

const H4 = styled.h4`
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 1rem;

  ${props => `
    @media screen and (min-width: 40em) {
      font-size: 18px;
    }
  `}
`

const ResponseiveFlex = styled(Flex)`
  flex-direction: column;

  ${props => `
 @media screen and (min-width: 40em) {
    flex-direction: column;
  }
 @media screen and (min-width: 52em) {
    flex-direction: column;

  }
 @media screen and (min-width: 64em) {
    flex-direction: row;
  }
`}
`

const Heading = styled(Box)`
  padding-top: 20px;
  padding-bottom: 30px;

  ${H4} {
    text-align: center;
    margin-bottom: 0.5rem;

    @media screen and (min-width: 64em) {
      text-align: left;
    }
  }

  p {
    text-align: center;
    margin: 0 auto;
    max-width: 18rem;

    @media screen and (min-width: 64em) {
      text-align: left;
      margin: 0;
      max-width: 18rem;
    }
  }
`

const UseItemContainer = styled(Box)`
  padding: 20px 8px;
`

const UseItemIcon = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 5px;
`

const UseItem = styled(Flex)`
  padding: 0 8px;
`

const UseHeading = styled.h3`
  padding-top: 3px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
`

const UseBody = styled.p`
  font-weight: 300;
`

export const WhyUse = () => {
  return (
    <Box paddingTop={35} paddingBottom={35}>
      <SafeArea>
        <ResponseiveFlex>
          <Heading flex={3}>
            <H4>Why use SparkThink?</H4>
            <UseBody>
              Gather feedback across your office or around the world. 
            </UseBody>
          </Heading>
          <Box flex={9}>
            <ResponseiveFlex flexWrap="wrap">
              <UseItemContainer flex={4}>
                <UseItem>
                  <UseItemIcon flex={2}>
                    <Organic color="#0C62FB" />
                  </UseItemIcon>
                  <Box flex={10}>
                    <UseHeading>It's intuitive</UseHeading>
                    <UseBody>
                      Setting up a SparkThink workshop or survey takes just
                      minutes, and participants have point-and-tap access to
                      Workshops with our QR Code feature.
                    </UseBody>
                  </Box>
                </UseItem>
              </UseItemContainer>
              <UseItemContainer flex={4}>
                <UseItem>
                  <UseItemIcon flex={2}>
                    <Speedometer color="#0C62FB" />
                  </UseItemIcon>
                  <Box flex={10}>
                    <UseHeading>It's powerful</UseHeading>
                    <UseBody>
                      Surveys can accommodate hundreds of thousands of
                      respondents, and Workshops can host up to 10,000+
                      simultaneous users.
                    </UseBody>
                  </Box>
                </UseItem>
              </UseItemContainer>
              <UseItemContainer flex={4}>
                <UseItem>
                  <UseItemIcon flex={2}>
                    <Chat color="#0C62FB" />
                  </UseItemIcon>
                  <Box flex={10}>
                    <UseHeading>It's engaging</UseHeading>
                    <UseBody>
                      SparkThink workshops and surveys are interactive, visually
                      engaging experiences that make taking surveys and sharing
                      ideas fun and effective.
                    </UseBody>
                  </Box>
                </UseItem>
              </UseItemContainer>
            </ResponseiveFlex>
            <ResponseiveFlex flexWrap="wrap">
              <UseItemContainer flex={4}>
                <UseItem>
                  <UseItemIcon flex={2}>
                    <Happy color="#0C62FB" />
                  </UseItemIcon>
                  <Box flex={10}>
                    <UseHeading>It's welcoming</UseHeading>
                    <UseBody>
                      With built-in anonymity, workshop participants feel safe
                      sharing their perspective, regardless of their position or
                      status.
                    </UseBody>
                  </Box>
                </UseItem>
              </UseItemContainer>
              <UseItemContainer flex={4}>
                <UseItem>
                  <UseItemIcon flex={2}>
                    <Integration color="#0C62FB" />
                  </UseItemIcon>
                  <Box flex={10}>
                    <UseHeading>It's effective</UseHeading>
                    <UseBody>
                      SparkThink gets results by making the process of gathering
                      perspectives and getting alignment simple and memorable.
                    </UseBody>
                  </Box>
                </UseItem>
              </UseItemContainer>
              <UseItemContainer flex={4}>
                <UseItem>
                  <UseItemIcon flex={2}>
                    <CloudUpload color="#0C62FB" />
                  </UseItemIcon>
                  <Box flex={10}>
                    <UseHeading>It's digital</UseHeading>
                    <UseBody>
                      No limitations. You can use SparkThink on any device, and
                      it captures, tabulates, and easily distributes everything
                      that’s discussed.
                    </UseBody>
                  </Box>
                </UseItem>
              </UseItemContainer>
            </ResponseiveFlex>
          </Box>
        </ResponseiveFlex>
      </SafeArea>
    </Box>
  )
}
